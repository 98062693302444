@font-face {
  font-family: "Metropolis";
  font-style: normal;
  font-display: block;
  font-weight: normal;
  src: local("Metropolis"), local("Metropolis-Regular"),
    url("../public/fonts/Metropolis-Regular.woff2");
}

@font-face {
  font-family: "Metropolis";
  font-style: normal;
  font-display: block;
  font-weight: bold;
  src: local("Metropolis"), local("Metropolis-Bold"),
    url("../public/fonts/Metropolis-Bold.woff2");
}

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html {
  background: black;
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
}

#root {
  height: 100%;
  overflow-x: hidden;
  position: relative;
}

a {
  text-decoration: none;
  color: inherit;
}
